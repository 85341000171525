<ng-container>
    <button
        (click)="open()"
        *ngIf="!opened"
        mat-icon-button>
        <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
    </button>
    <div
        *ngIf="opened"
        @slideInTop
        @slideOutTop
        class="absolute inset-0 flex items-center shrink-0 z-99 bg-card">
        <mat-icon
            [svgIcon]="'heroicons_outline:magnifying-glass'"
            class="absolute ml-6 sm:ml-8"></mat-icon>
        <input
            #barSearchInput
            (keydown)="onKeydown($event)"
            [formControl]="searchControl"
            [matAutocomplete]="matAutocomplete"
            [placeholder]="'Search...'"
            class="w-full h-full px-16 sm:px-18">
        <mat-autocomplete
            #matAutocomplete="matAutocomplete"
            [autoSelectActiveOption]="true"
            [disableRipple]="true"
            class="max-h-128 sm:px-2 border-t rounded-b shadow-md">
            <mat-option
                *ngIf="resultSets && !resultSets.length"
                class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent">
                No results found!
            </mat-option>
            <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <mat-option
                    (click)="goTo(resultSet.link)"
                    class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover">
                    <div class="flex flex-col">
                        <div
                            [innerHTML]="resultSet.subtitle"
                            class="truncate leading-normal"></div>
                        <div class="truncate leading-normal text-sm text-secondary">
                            {{resultSet.link}}
                        </div>
                    </div>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
        <button
            (click)="close()"
            class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5"
            mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
</ng-container>
