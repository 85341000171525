import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/services/auth/auth.service';

export function isLoggedIn(): CanActivateFn {
    return () => {
        const router: Router = inject(Router);
        const authService: AuthService = inject(AuthService);

        if (authService.accessToken) {
            return true;
        } else {
            router.navigate(['/sign-in']);
            return false;
        }
    };
}

export function isNotLogged(): CanActivateFn {
    return () => {
        const router: Router = inject(Router);
        const authService: AuthService = inject(AuthService);

        if (!authService.accessToken) {
            return true;
        } else {
            router.navigate(['/home/workspace']);
            return false;
        }
    };
}
