import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WorkspaceService } from '../../../modules/home/workspace/workspace.service';
import { Workspace, WorkspaceNotification } from '../../../modules/home/workspace/workspace.interface';
import { RequestAction } from '../../../core/interfaces/action.interface';
import { AuthService } from '../../../core/services/auth/auth.service';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    standalone: true,
    imports: [ MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe ],
})
export class NotificationsComponent implements OnInit, OnDestroy {
    notifications: WorkspaceNotification[];
    unreadCount: number = 0;
    @ViewChild('notificationsOrigin') private notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private notificationsPanel: TemplateRef<any>;
    private overlayRef: OverlayRef;

    constructor(
        private destroyRef: DestroyRef,
        private cd: ChangeDetectorRef,
        private workspaceService: WorkspaceService,
        private authService: AuthService,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private router: Router) {
    }

    ngOnInit(): void {
        this.workspaceService.workspace$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((workspace: Workspace) => {
                if (workspace) {
                    this.notifications = workspace.notifications;
                    this.calculateUnreadCount();
                    this.cd.markForCheck();
                } else {
                    this.notifications = [];
                    this.cd.markForCheck();
                }
            });
    }
    ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    openPanel(): void {
        if (!this.notificationsPanel || !this.notificationsOrigin) {
            return;
        }

        if (!this.overlayRef) {
            this.createOverlay();
        }

        this.overlayRef.attach(new TemplatePortal(this.notificationsPanel, this.viewContainerRef));
    }

    closePanel(): void {
        this.overlayRef.detach();
    }

    markAllAsRead(): void {
        this.calculateUnreadCount();
    }

    toggleRead(notification: WorkspaceNotification, action: 'READ' | 'UNREAD' = null): void {

        if (!action) {
            action = this.isUnread(notification.statusName) ? 'READ' : 'UNREAD';
        }
        const body: Partial<RequestAction> = {
            action,
            id1: notification.notificationID + '',
            param1: this.authService.getUsername(),
        }
        this.workspaceService.updateNotification(body)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(resp => {
                if (action) {
                    notification.statusName = action === 'READ' ? 'Read' : 'Open';
                } else {
                    notification.statusName = this.isUnread(notification.statusName) ? 'Read' : 'Open';
                }
                this.calculateUnreadCount();
            });
    }

    delete(notification: WorkspaceNotification): void {
        let action = 'DEL';
        const body: Partial<RequestAction> = {
            action,
            id1: notification.notificationID + '',
            param1: this.authService.getUsername(),
        }
        this.workspaceService.updateNotification(body)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(resp => {
                const index = this.notifications.findIndex((i) => i.notificationID === notification.notificationID);
                this.notifications.splice(index, 1);
                this.calculateUnreadCount();
            });
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    private createOverlay(): void {
        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: this.overlay.position()
                .flexibleConnectedTo(this.notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });
    }

    private calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(notification => this.isUnread(notification.statusName)).length;
        }

        this.unreadCount = count;
        this.cd.detectChanges();
    }

    openNotification(notification: WorkspaceNotification) {
        this.toggleRead(notification, 'READ');
        this.router.navigateByUrl(notification.notificationURL);
        this.closePanel();

    }
    isUnread(value: string) {
        return value === 'Open';
    }
}
