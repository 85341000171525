import { Component, DestroyRef, Inject, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { combineLatest, filter, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FuseConfig, FuseConfigService } from '../@fuse/services/config';
import { FuseMediaWatcherService } from '../@fuse/services/media-watcher';
import { FuseNavigationService } from '../@fuse/components/navigation';
import { DOCUMENT } from '@angular/common';
import { AuthService } from './core/services/auth/auth.service';
import { WorkspaceService } from './modules/home/workspace/workspace.service';
import DateBox, { Properties } from "devextreme/ui/date_box";
import dxDataGrid from 'devextreme/ui/data_grid';
import dxTreeList from "devextreme/ui/tree_list";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ],
    standalone: true,
    imports: [ RouterOutlet ],
})
export class AppComponent {
    destroyRef = inject(DestroyRef);


    config: FuseConfig;
    layout: string;
    scheme: 'dark' | 'light';
    theme: string;
    interval = null;

    constructor(@Inject(DOCUMENT) private _document: any,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private workspaceService: WorkspaceService,
                private authService: AuthService,
                private fuseMediaWatcherService: FuseMediaWatcherService,
                private fuseConfigService: FuseConfigService) {
        DateBox.defaultOptions<Properties>({
            options: {
                dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss"
            }
        });
        dxDataGrid.defaultOptions({
           options: {
               scrolling: {
                   useNative: true,
                   preloadEnabled: true
               },
           }
        });
        dxTreeList.defaultOptions({
            options: {
                scrolling: {
                    useNative: true,
                    preloadEnabled: true
                }
            }
        });
        this.setupFuse();
        this.getNotification();
    }

    getNotification() {
        this.authService.user$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(resp => {
               if (resp) {
                   this.fetchTask();
                   clearInterval(this.interval);
                   this.interval = setInterval(() => {
                       this.fetchTask();
                   }, 60 * 1000);
               }
            });
    }

    fetchTask() {
        this.workspaceService.getTask()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((resp) => {
                if (resp.data) {
                    this.workspaceService.workspace$.next(resp.data);
                }
            });
    }


    setupFuse() {
        combineLatest([
            this.fuseConfigService.config$,
            this.fuseMediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)']),
        ]).pipe(
            takeUntilDestroyed(this.destroyRef),
            map(([config, mql]) => {
                const options = {
                    scheme: config.scheme,
                    theme : config.theme,
                };
                if ( config.scheme === 'auto' ) {
                    options.scheme = mql.breakpoints['(prefers-color-scheme: dark)'] ? 'dark' : 'light';
                }

                return options;
            }),
        ).subscribe((options) =>
        {
            this.scheme = options.scheme;
            this.theme = options.theme;

            // Update the scheme and theme
            this._updateScheme();
            this._updateTheme();
        });

        // Subscribe to config changes
        this.fuseConfigService.config$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((config: FuseConfig) => {
                this.config = config;
                this._updateLayout();
            });

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd), takeUntilDestroyed(this.destroyRef),)
            .subscribe(() => {
                this._updateLayout();
            });
    }

    private _updateScheme(): void {
        this._document.body.classList.remove('light', 'dark');
        this._document.body.classList.add(this.scheme);
    }

    private _updateTheme(): void {
        this._document.body.classList.forEach((className: string) => {
            if ( className.startsWith('theme-') ) {
                this._document.body.classList.remove(className, className.split('-')[1]);
            }
        });
        this._document.body.classList.add(this.theme);
    }

    private _updateLayout(): void {
        let route = this.activatedRoute;
        while ( route.firstChild ) {
            route = route.firstChild;
        }
        this.layout = this.config.layout;

        const layoutFromQueryParam = route.snapshot.queryParamMap.get('layout');
        if ( layoutFromQueryParam ) {
            this.layout = layoutFromQueryParam;
            if ( this.config ) {
                this.config.layout = layoutFromQueryParam;
            }
        }

        const paths = route.pathFromRoot;
        paths.forEach((path) => {
            if ( path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout ) {
                this.layout = path.routeConfig.data.layout;
            }
        });
    }
}
