import { Component, DestroyRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DEFAULT_LOV_USERS } from '../../../core/constants/lov.const';
import { DxButtonModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule } from 'devextreme-angular';
import { MatDividerModule } from '@angular/material/divider';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UsersService } from '../../../core/services/secure/users.service';
import { AuthService } from '../../../core/services/auth/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LovService } from '../../../core/services/core/lov.service';
import { LovUsers } from '../../../core/interfaces/lov.interface';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-profile-dialog',
  standalone: true,
    imports: [ CommonModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule, MatDividerModule, ReactiveFormsModule, MatIconModule, DxButtonModule ],
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss']
})
export class EditProfileDialogComponent {

    lov: LovUsers = DEFAULT_LOV_USERS;
    formGroup: FormGroup = new FormGroup({
        action: new FormControl('MOD'),
        userName: new FormControl(),
        userTitle: new FormControl(),
        userDescription: new FormControl(),
        userType: new FormControl(),
        userGroup: new FormControl(),
        userLinkType: new FormControl(),
        userLink: new FormControl(),
        userDivision: new FormControl(),
        userDepartment: new FormControl(),
        emailAddress: new FormControl(),
        mobilePhone: new FormControl(),
        notes: new FormControl(),
    });

    isEditing = true;
    userDetail: any

    constructor(
        protected usersService: UsersService,
        private authService: AuthService,
        private destroyRef: DestroyRef,
        private lovService: LovService,
        private matDialogRef: MatDialogRef<EditProfileDialogComponent>) {
    }

    ngOnInit() {
        this.fetchLov();
        this.fetchUser();
    }

    fetchUser() {
        const username = this.authService.user$.value.userName;
        this.usersService.getDetail(username)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((response) => {
                this.userDetail = response.data
                this.formGroup.patchValue(response.data);
                this.authService.setUserData(response.data);
                this.formGroup.get('action').setValue('MOD');
            })
    }

    fetchLov() {
        this.lovService.getLovUsers().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((resp) => {
            this.lov = resp.data;
        });
    }

    cancel() {
        this.isEditing = false;
    }

    back() {
        this.matDialogRef.close();
    }

    edit() {
        this.isEditing = true;
    }

    save() {
        const body = this.formGroup.getRawValue();
        this.usersService.upsert(body)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((response) => {
                const user = {
                    ...this.authService.user$.value,
                    ...body
                };
                this.authService.user$.next(user);
                this.matDialogRef.close();
        });
    }

    onFileUploaded($event) {
        const username = this.authService.user$.value.userName;
        const data = $event.target.files[0]
        const formData = new FormData();
        formData.append('file', data);
        this.usersService.uploadPicture(username, formData).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.fetchUser();
        })
    }
}
