import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NetworkService } from '../services/helper/network.service';

@Injectable()
export class ToastInterceptor implements HttpInterceptor {
    networkCount = 0;

    constructor(private toastService: ToastrService,
                private networkService: NetworkService,
                private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        this.networkCount++;
        this.networkService.setValue(this.networkCount);
        let disableToast = request.headers.has('disabledToast') && request.headers.get('disabledToast');
        if (disableToast) {
            request = request.clone({
                headers: request.headers.delete('disabledToast', 'true'),
            });
        }

        return next.handle(request).pipe(
            tap(response => {
                if (response instanceof HttpResponse) {
                    if (request.method === 'POST' && !disableToast)
                        this.toastService.success(response.body.message);
                }
            }),
            catchError((response: HttpErrorResponse) => {
                if (!response?.error?.errors) { throwError(response); }

                const title = response.error.message;
                const err = response.error.errors;
                let message = '';

                if (title === 'Error Occurred.' || title === 'Bad Request' || !title) {
                    this.toastService.error(null, title || 'Bad Request',{
                        tapToDismiss: false
                    });
                    return throwError(response);
                } else {
                    if (err instanceof Array) {
                        err.forEach((e) => {
                            message += `- ${e.message} <br>`
                        });
                        this.toastService.warning(message, title, {
                            enableHtml: true,
                            tapToDismiss: false
                        });
                        return throwError(response);
                    }
                    if (err instanceof Object) {
                        this.toastService.warning(title);
                    }
                }

			}),
            finalize(() => {
                this.networkCount--;
                this.networkService.setValue(this.networkCount);
            })
		);
	}
}
