import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    DxButtonModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxValidatorComponent,
    DxValidatorModule
} from 'devextreme-angular';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../core/services/auth/auth.service';
import { UsersService } from '../../../core/services/secure/users.service';
import { environment } from '../../../../environments/environment';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-change-password-dialog',
    standalone: true,
    imports: [CommonModule, DxButtonModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule, MatDividerModule, MatIconModule, ReactiveFormsModule, DxValidatorModule],
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent {
    @ViewChild('targetValidator', { static: false }) validator: DxValidatorComponent;

    formGroup: FormGroup = new FormGroup({
        "action": new FormControl('CHP'),
        "id1": new FormControl(''),
        "param1": new FormControl(''),
        "param2": new FormControl(''),
        "param3": new FormControl(''),
    });

    constructor(private http: HttpClient,
        private authService: AuthService,
        private usersService: UsersService,
        private matDialogRef: MatDialogRef<ChangePasswordDialogComponent>,
        private router: Router) {
    }

    save(event: any) {
        let result = event.validationGroup.validate();
        if (result.isValid) {
            const username = this.authService.user$.value.userName;
            this.formGroup.patchValue({ id1: username });
            this.formGroup.markAllAsTouched();
            const body = this.formGroup.value;
            delete body.param3;
            this.http.post(`${environment.API_URL}/api/secure/users/action`, body).subscribe(resp => {
                this.router.navigate(['/sign-out']);
                this.matDialogRef.close();
            });
        }
    }

    cancel() {
        const isForceChangePassoword = this.authService.user$.value?.isForceChangePassoword
        if (isForceChangePassoword) {
            this.authService.signOut()
            this.router.navigate(['/sign-out']);
            this.matDialogRef.close()
        } else this.matDialogRef.close()
    }

    passwordComparison = () => this.formGroup.get('param2').value;

    onPasswordChanged() {
        if (this.formGroup.get('param2').value && this.formGroup.get('param3').value) {
            this.validator.instance.validate();
        }
    }
}
