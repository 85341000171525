<button
    class="navbar-img"
    [matMenuTriggerFor]="userActions"
    mat-icon-button>
    <img
        [src]="user?.profileImageFile ?? '/assets/icons/no-picture.svg'"
        alt="User avatar">
</button>

<mat-menu
    #userActions="matMenu"
    [xPosition]="'before'">
    <button mat-menu-item>
        <div class="flex flex-row items-center gap-2 py-3">
            <div style="width: 50px;">
                <img
                    [src]="user?.profileImageFile ?? '/assets/icons/no-picture.svg'"
                    alt="User avatar"
                    class="w-full rounded-full">
            </div>
            <div>
                <span class="flex flex-col leading-none">
                    <span class="mt-1.5 text-md font-medium">{{user?.userName}}</span>
                    <span class="mt-1.5 text-md font-normal">{{user?.userDescription}}</span>
                    <span class="mt-1.5 text-md font-normal">{{user?.userTitle}}</span>
                </span>
            </div>
        </div>
    </button>
    <mat-divider class="mb-2"></mat-divider>
    <button mat-menu-item (click)="openProfile()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item (click)="changePassword()">
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Change Password</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        (click)="signOut()"
        mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>
