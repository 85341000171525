import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/services/auth/auth.service';

export function validateAccess(accessType: string): CanActivateFn {
    return () => {

        const authService = inject(AuthService);
        const router = inject(Router);
        const accessList = authService.accessList$.value;

        const exist = accessList?.findIndex((a) => a.objectCode === accessType) !== -1;
        if (accessList && exist) {
            return true;
        } else {
            router.navigate(['/unauthorized']);
            return false;
        }
    };
}
