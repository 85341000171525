import { NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { Subject } from 'rxjs';

@Component({
    selector: 'layout-empty',
    templateUrl: './layout-empty.component.html',
    styleUrls: [ './layout-empty.component.scss' ],
    standalone: true,
    imports: [ FuseLoadingBarComponent, NgIf, RouterOutlet ],
})
export class LayoutEmptyComponent implements OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor() {
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
