import { NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseLoadingBarComponent } from '../../@fuse/components/loading-bar';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from '../../@fuse/components/navigation';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NotificationsComponent } from './common/notifications/notifications.component';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { UserComponent } from './common/user/user.component';
import { FuseMediaWatcherService } from '../../@fuse/services/media-watcher';
import { MatDividerModule } from '@angular/material/divider';
import { AuthService } from '../core/services/auth/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from '../core/interfaces/users.interface';
import { SearchComponent } from './common/search/search.component';
import { SidebarService } from '../core/services/helper/sidebar.service';
import { WorkspaceUser } from '../modules/home/workspace/workspace.interface';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: [ './layout.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [ NgIf, FuseLoadingBarComponent, FuseVerticalNavigationComponent, MatButtonModule, MatIconModule, NgForOf, NotificationsComponent, RouterLinkActive, RouterOutlet, UserComponent, RouterLink, MatDividerModule, SearchComponent, UpperCasePipe, ],
})
export class LayoutComponent implements OnInit {
    isScreenSmall: boolean = false;
    user: User;
    workspace: WorkspaceUser;
    sidebarNavigationItems: FuseNavigationItem[] = [];
    destroyRef = inject(DestroyRef);
    isOpened = false;
    isHovered = false;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fuseMediaWatcherService: FuseMediaWatcherService,
                private fuseNavigationService: FuseNavigationService,
                private authService: AuthService,
                private sidebarService: SidebarService,) {
    }

    initSidebar() {
        this.sidebarService.sidebarItems$.subscribe(sidebarList => {
            this.sidebarNavigationItems = sidebarList;
        });
    }

    ngOnInit(): void {
        this.authService.user$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((resp) => {
                this.user = resp;
            });
        this.authService.workspace$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((resp) => {
                this.workspace = resp;
            });
        this.sidebarService.isOpened$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((resp) => {
                this.isOpened = resp;
            });
        this.sidebarService.isHovered$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((resp) => {
                this.isHovered = resp;
            });
        this.initSidebar();
    }

    toggleNavigation(): void {
        this.sidebarService.toggle();
    }
}
