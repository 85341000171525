<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="sidebarNavigationItems"
    [opened]="!isScreenSmall && isOpened"
    class="dark bg-gray-900 print:hidden">
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center justify-center header-container">
            <img
                class="w-15 mr-2"
                [class.closed]="!isOpened && !isHovered"
                src="assets/images/logo/indosurance-broker.png"
            />
            @if (isOpened || isHovered) {
                <span class="font-bold text-xl text-title">GENISYS</span>
            }
        </div>
        <div class="header-divider"></div>
<!--        <div class="flex flex-col items-center w-full p-4 mt-12">-->
<!--            <div class="relative w-28 h-28">-->
<!--                <img-->
<!--                    [src]="user?.profileImageFile ?? '/assets/icons/no-picture.svg'"-->
<!--                    alt="User avatar"-->
<!--                    class="w-full h-full rounded-full">-->
<!--            </div>-->
<!--            <div class="flex flex-col items-center justify-center w-full mt-5">-->
<!--                <div-->
<!--                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">-->
<!--                    {{user?.userDescription}}-->
<!--                </div>-->
<!--                <div-->
<!--                    class="w-full mt-1 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">-->
<!--                    {{user?.userTitle}}-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div>
            <div class="divider mb-5"></div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center justify-between w-full px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden bg-white min-h-navbar">
        <!-- Navigation toggle button -->
        <div class="flex items-center gap-3">
            <button
                (click)="toggleNavigation()"
                mat-icon-button>
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
            <div class="font-medium text-xl">
                {{ workspace?.companyName | uppercase }}
            </div>
        </div>
<!--        <div class="flex items-center mx-2 lg:mr-8">-->
<!--            <img-->
<!--                class="w-15 mr-2"-->
<!--                src="assets/images/logo/indosurance-broker.png"-->
<!--            />-->
<!--            <span class="font-bold text-xl">GENISYS</span>-->
<!--        </div>-->
        <div class="flex">
            <search></search>
            <notifications></notifications>
            <user></user>
        </div>

    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

</div>

