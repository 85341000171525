import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth/auth.service';
import { User } from '../../../core/interfaces/users.interface';
import { MatDialog } from '@angular/material/dialog';
import {
    EditProfileDialogComponent
} from '../../../shared/components/edit-profile-dialog/edit-profile-dialog.component';
import {
    ChangePasswordDialogComponent
} from '../../../shared/components/change-password-dialog/change-password-dialog.component';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    exportAs: 'user',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule],
})
export class UserComponent implements OnInit {

    static ngAcceptInputType_showAvatar: BooleanInput;
    user: User = null;

    @Input() showAvatar: boolean = true;

    constructor(
        private router: Router,
        private authService: AuthService,
        private matDialog: MatDialog) {
    }

    ngOnInit(): void {
        this.authService.user$.subscribe(resp => { this.user = resp })
    }

    signOut(): void {
        this.router.navigate(['/sign-out']);
    }

    openProfile() {
        this.matDialog.open(EditProfileDialogComponent);
    }

    changePassword() {
        this.matDialog.open(ChangePasswordDialogComponent, {
            width: '800px'
        });
    }
}
