<div class="nxe-modal">
    <div class="nxe-modal-header">
        <div>
            <p class="subheader"> Change Password </p>
        </div>
    </div>
    <div class="nxe-dialog-content">
        <form [formGroup]="formGroup">
            <div class="grid grid-cols-8 gap-2 mb-1 px-8">
                <div class="col-start-1 col-span-8 my-3">
                    <div class="dx-field flex flex-row">
                        <div class="dx-field-label font-medium w-1/2">
                            Current Password
                        </div>
                        <div class="dx-field-value w-full">
                            <dx-text-box mode="password" formControlName="param1">
                                <dx-validator>
                                    <dxi-validation-rule type="required" message="Current password is required">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                    <div class="dx-field flex flex-row">
                        <div class="dx-field-label font-medium w-1/2">
                            New Password
                        </div>
                        <div class="dx-field-value w-full">
                            <dx-text-box mode="password" (onValueChanged)="onPasswordChanged()"
                                formControlName="param2">
                                <dx-validator>
                                    <dxi-validation-rule type="required" message="New password is required">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                    <div class="dx-field flex flex-row">
                        <div class="dx-field-label font-medium w-1/2">
                            New Password Confirmation
                        </div>
                        <div class="dx-field-value w-full">
                            <dx-text-box mode="password" (onValueChanged)="onPasswordChanged()"
                                formControlName="param3">
                                <dx-validator #targetValidator>
                                    <dxi-validation-rule type="required"
                                        message="New password confirmation is required">
                                    </dxi-validation-rule>
                                    <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                                        message="Password and password confirmation do not match">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="nxe-modal-footer">
        <dx-button buttonLoading (onClick)="save($event)" class="nxe-btn-primary" text="Continue" type="default" />
        <dx-button (onClick)="cancel()" text="Cancel" />
    </div>
</div>