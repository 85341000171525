import { Overlay } from '@angular/cdk/overlay';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
    MatAutocomplete,
    MatAutocompleteModule
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { fuseAnimations } from '@fuse/animations/public-api';
import { debounceTime, filter, map } from 'rxjs';
import { SidebarService } from '../../../core/services/helper/sidebar.service';
import { FuseNavigationItem } from '../../../../@fuse/components/navigation';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations,
    standalone: true,
    imports: [ NgIf, MatButtonModule, MatIconModule, FormsModule, MatAutocompleteModule, ReactiveFormsModule, MatOptionModule, NgFor, RouterLink, NgTemplateOutlet, MatFormFieldModule, MatInputModule, NgClass ],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: () => {
                const overlay = inject(Overlay);
                return () => overlay.scrollStrategies.block();
            },
        },
    ],
})
export class SearchComponent implements OnChanges, OnInit {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 2;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    opened: boolean = false;
    resultSets: FuseNavigationItem[];
    searchControl: UntypedFormControl = new UntypedFormControl();
    sidebarList: FuseNavigationItem[] = [];

    constructor(
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2,
        private sidebarService: SidebarService,
        private router: Router) {
        this.sidebarService.sidebarItems$.subscribe(sidebarList => {
            sidebarList = cloneDeep(sidebarList);
            const stack = [];
            const temp = [];
            sidebarList.forEach(nav => {
                nav.children?.forEach(c => {
                    stack.push(c);
                });
                delete nav.children;
                temp.push(nav);
            })

            while (stack.length > 0) {
                const child = stack.pop();
                child.children?.forEach(c => {
                    stack.push(c);
                });
                delete child.children;
                temp.push(child);
            }

            this.sidebarList = temp;
        })
    }

    private _matAutocomplete: MatAutocomplete;

    @ViewChild('matAutocomplete')
    set matAutocomplete(value: MatAutocomplete) {
        this._matAutocomplete = value;
    }

    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened,
        };
    }

    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        if (value) {
            setTimeout(() => {
                value.nativeElement.focus();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('appearance' in changes) {
            this.close();
        }
    }

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                map((value) => {
                    if (!value || value.length < this.minLength) {
                        this.resultSets = null;
                    }

                    // Continue
                    return value;
                }),
                filter(value => value && value.length >= this.minLength),
            )
            .subscribe((value) => {
                value = (value + '').toLowerCase();
                this.resultSets = this.sidebarList.filter(s =>
                    s.subtitle?.toLowerCase().includes(value) && s.type === 'basic');
                // this._httpClient.post('api/common/search', { query: value })
                //     .subscribe((resultSets: any) => {
                //         this.resultSets = resultSets;
                //         this.search.next(resultSets);
                //     });
            });
    }

    onKeydown(event: KeyboardEvent): void {
        if (event.code === 'Escape') {
            if (this.appearance === 'bar' && !this._matAutocomplete.isOpen) {
                this.close();
            }
        }
    }

    open(): void {
        if (this.opened) {
            return;
        }

        this.opened = true;
    }

    close(): void {
        if (!this.opened) {
            return;
        }
        this.searchControl.setValue('');
        this.opened = false;
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    goTo(url: string) {
        this.router.navigateByUrl(url);
        this.close();
    }
}
