<div class="nxe-modal">
    <div class="nxe-modal-header">
        <mat-icon svgIcon="heroicons_outline:user">
        </mat-icon>
        <div>
            <p class="subheader"> Profile </p>
        </div>
    </div>
    <div class="nxe-dialog-content">
        <form [formGroup]="formGroup">
            <div class="grid grid-cols-8 gap-2 mb-1 px-8 mt-4">
                <div class="col-start-1 col-span-8 mt-3">
                    <div class="img-container">
                        <img [src]="userDetail?.profileImageFile ?? '/assets/icons/no-picture.svg'" class="rounded-full" />
                        <div class="image-upload w-full mt-6">
                            <label for="file-input" class="btn">Upload Image</label>
                            <input id="file-input" type="file" accept="image/*" (change)="onFileUploaded($event)">
                        </div>
                    </div>
                    <div class="dx-field flex flex-row mt-6">
                        <div class="dx-field-label font-medium w-48">
                            User Name
                        </div>
                        <div class="dx-field-value w-full">
                            <dx-text-box formControlName="userName" [hoverStateEnabled]="!isEditing"
                                placeholder="User name..." [readOnly]="true">
                            </dx-text-box>
                        </div>
                    </div>
                    <div class="dx-field flex flex-row">
                        <div class="dx-field-label font-medium w-48">
                            User Title
                        </div>
                        <div class="dx-field-value w-full">
                            <dx-text-box formControlName="userTitle" [readOnly]=" !isEditing"
                                placeholder="User title...">
                            </dx-text-box>
                        </div>
                    </div>
                    <div class="dx-field flex flex-row">
                        <div class="dx-field-label font-medium w-48">
                            User Description
                        </div>
                        <div class="dx-field-value w-full">
                            <dx-text-box formControlName="userDescription" placeholder="Description..."
                                [readOnly]="!isEditing">
                            </dx-text-box>
                        </div>
                    </div>
                    <div [hidden]="true">
                        <div class="dx-field flex flex-row">
                            <div class="dx-field-label font-medium w-48">
                                User Type
                            </div>
                            <div class="dx-field-value w-full">
                                <dx-select-box formControlName="userType" [dataSource]="lov.userTypeSource"
                                    displayExpr="lovName" valueExpr="lovCode" [searchMode]="'contains'"
                                    [searchExpr]="['lovName', 'lovCode']" [searchTimeout]="200" [minSearchLength]="0"
                                    [searchEnabled]="true" [showDataBeforeSearch]="false" [showClearButton]="true"
                                    [readOnly]="!isEditing" placeholder="User type...">
                                </dx-select-box>
                            </div>
                        </div>
                        <div class="dx-field flex flex-row">
                            <div class="dx-field-label font-medium w-48">
                                User Group
                            </div>
                            <div class="dx-field-value w-full">
                                <dx-select-box formControlName="userGroup" [dataSource]="lov.userGroupSource"
                                    displayExpr="lovName" valueExpr="lovCode" [searchMode]="'contains'"
                                    [searchExpr]="['lovName', 'lovCode']" [searchTimeout]="200" [minSearchLength]="0"
                                    [searchEnabled]="true" [showDataBeforeSearch]="false" [showClearButton]="true"
                                    [readOnly]="!isEditing" placeholder="User group...">
                                </dx-select-box>
                            </div>
                        </div>
                        <div class="dx-field flex flex-row">
                            <div class="dx-field-label font-medium w-48">
                                User Link Type
                            </div>
                            <div class="dx-field-value w-full">
                                <dx-select-box formControlName="userLinkType" [dataSource]="lov.userLinkTypeSource"
                                    displayExpr="lovName" valueExpr="lovCode" [searchMode]="'contains'"
                                    [searchExpr]="['lovName', 'lovCode']" [searchTimeout]="200" [minSearchLength]="0"
                                    [searchEnabled]="true" [showDataBeforeSearch]="false" [showClearButton]="true"
                                    [readOnly]="!isEditing" placeholder="User Link Type...">
                                </dx-select-box>
                            </div>
                        </div>
                        <div class="dx-field flex flex-row">
                            <div class="dx-field-label font-medium w-48">
                                User Division
                            </div>
                            <div class="dx-field-value w-full">
                                <dx-select-box formControlName="userDivision" [dataSource]="lov.userDivisionSource"
                                    displayExpr="lovName" valueExpr="lovCode" [searchMode]="'contains'"
                                    [searchExpr]="['lovName', 'lovCode']" [searchTimeout]="200" [minSearchLength]="0"
                                    [searchEnabled]="true" [showDataBeforeSearch]="false" [showClearButton]="true"
                                    [readOnly]="!isEditing" placeholder="Division...">
                                </dx-select-box>
                            </div>
                        </div>
                        <div class="dx-field flex flex-row">
                            <div class="dx-field-label font-medium w-48">
                                User Department
                            </div>
                            <div class="dx-field-value w-full">
                                <dx-select-box formControlName="userDepartment" [dataSource]="lov.userDepartmentSource"
                                    displayExpr="lovName" valueExpr="lovCode" [searchMode]="'contains'"
                                    [searchExpr]="['lovName', 'lovCode']" [searchTimeout]="200" [minSearchLength]="0"
                                    [searchEnabled]="true" [showDataBeforeSearch]="false" [showClearButton]="true"
                                    [readOnly]="!isEditing" placeholder="Department...">
                                </dx-select-box>
                            </div>
                        </div>
                    </div>
                    <mat-divider class="col-start-1 col-span-12 my-2"></mat-divider>
                    <div class="dx-field flex flex-row">
                        <div class="dx-field-label font-medium w-48">
                            Email Address
                        </div>
                        <div class="dx-field-value w-full">
                            <dx-text-box formControlName="emailAddress" placeholder="Email address..."
                                [readOnly]="!isEditing">
                            </dx-text-box>
                        </div>
                    </div>
                    <div class="dx-field flex flex-row">
                        <div class="dx-field-label font-medium w-48">
                            Mobile Phone
                        </div>
                        <div class="dx-field-value w-full">
                            <dx-text-box formControlName="mobilePhone" placeholder="Mobile phone..."
                                [readOnly]="!isEditing">
                            </dx-text-box>
                        </div>
                    </div>
                    <mat-divider class="col-start-1 col-span-12 my-2"></mat-divider>
                    <div class="dx-field flex flex-row">
                        <div class="dx-field-label font-medium w-48">
                            Notes
                        </div>
                        <div class="dx-field-value w-full">
                            <dx-text-area formControlName="notes" placeholder="Notes..." [height]="80"
                                [readOnly]="!isEditing">
                            </dx-text-area>
                        </div>
                    </div>
                    <!-- <div>
                        <div class="image-upload w-full mt-6">
                            <label for="file-input" class="btn">Upload Image</label>
                            <input id="file-input" type="file" accept="image/*" (change)="onFileUploaded($event)">
                        </div>
                    </div> -->
                </div>
            </div>
        </form>
    </div>
    <div class="nxe-modal-footer mt-6">
        <!-- <ng-container *ngIf="!isEditing">
            <dx-button
                (onClick)="back()"
                text="Back"/>
            <dx-button
                (onClick)="edit()"
                class="nxe-btn-primary"
                text="Edit"
                type="default"/>
    </ng-container> -->

        <ng-container *ngIf="isEditing">
            <dx-button (onClick)="back()" width="100">
                <mat-icon>cancel</mat-icon>
                <span>Cancel</span>
            </dx-button>
            <dx-button (onClick)="save()" class="nxe-btn-primary" type="default" width="100">
                <mat-icon>save</mat-icon>
                <span>Save</span>
            </dx-button>
        </ng-container>
    </div>
</div>