import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { LayoutEmptyComponent } from './layout/layout-empty/layout-empty.component';
import { isLoggedIn, isNotLogged } from './core/guards/auth.guard';
import { ACCESS_ROUTE } from './core/access/route.access';
import { validateAccess } from './core/guards/access.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    {path: '', pathMatch : 'full', redirectTo: 'home'},
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'home'},
    {
        path: '',
        component: LayoutEmptyComponent,
        canActivate: [isNotLogged()],
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')},
        ]
    },


    {
        path: '',
        component: LayoutEmptyComponent,
        canActivate: [isLoggedIn()],
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
        ]
    },

    {
        path: '',
        component: LayoutComponent,
        canActivate: [isLoggedIn()],
        children: [
            {
                path: 'notifications/:id',
                loadComponent: () => import('app/shared/components/notifications-detail/notifications-detail.component').then(c => c.NotificationsDetailComponent)
            },
            {
                path: 'unauthorized',
                loadComponent: () => import('app/shared/components/unauthorized/unauthorized.component').then(c => c.UnauthorizedComponent)
            },
            {
                path: 'home',
                children: [
                    {
                        path: '',
                        redirectTo: 'workspace',
                        pathMatch: 'full',
                    },
                    {
                        path: 'dashboard',
                        loadChildren: () => import('../app/modules/home/dashboard/dashboard.module').then(m => m.DashboardModule),
                    },
                    {
                        path: 'workspace',
                        loadChildren: () => import('../app/modules/home/workspace/workspace.module').then(m => m.WorkspaceModule),
                    },
                ],
            },
            {
                path: 'contracts',
                canActivate: [validateAccess(ACCESS_ROUTE.CONTRACT)],
                children: [
                    {
                        path: '',
                        redirectTo: 'policy-facultative',
                        pathMatch: 'full',
                    },
                    {
                        path: 'policy-facultative',
                        canActivate: [validateAccess(ACCESS_ROUTE.FACULTATIVE_CONTRACT)],
                        loadChildren: () => import('./modules/parties/policy-facultative/policy-facultative.module').then(m => m.PolicyFacultativeModule),
                    },
                    {
                        path: 'treaty',
                        canActivate: [validateAccess(ACCESS_ROUTE.TREATY_CONTRACT)],
                        loadChildren: () => import('../app/modules/parties/treaty/treaty.module').then(m => m.TreatyModule),
                    },
                    {
                        path: 'facility',
                        loadChildren: () => import('../app/modules/parties/facility/facility.module').then(m => m.FacilityModule),
                        data: {
                            accessName: ACCESS_ROUTE.FACULTATIVE_CONTRACT
                        }
                    },
                    {
                        path: 'contract-transactions',
                        loadChildren: () => import('../app/modules/parties/contract-transactions/contract-transactions.module').then(m => m.ContractTransactionsModule),
                        data: {
                            accessName: ''
                        }
                    },
                    {
                        path: 'agent',
                        canActivate: [validateAccess(ACCESS_ROUTE.AGENT)],
                        loadChildren: () => import('../app/modules/parties/agent/agent.module').then(m => m.AgentModule),
                    },
                    {
                        path: 'ceding',
                        canActivate: [validateAccess(ACCESS_ROUTE.CEDING)],
                        loadChildren: () => import('../app/modules/parties/ceding/ceding.module').then(m => m.CedingModule),
                    },
                    {
                        path: 'reinsurance',
                        canActivate: [validateAccess(ACCESS_ROUTE.REINSURER)],
                        loadChildren: () => import('../app/modules/parties/reinsurance/reinsurance.module').then(m => m.ReinsuranceModule),
                    },
                    {
                        path: 'client',
                        canActivate: [validateAccess(ACCESS_ROUTE.CLIENT)],
                        loadChildren: () => import('../app/modules/parties/client/client.module').then(m => m.ClientModule),
                    },
                ],
            },

            {
                path: 'operation',
                children: [
                    {
                        path: '',
                        redirectTo: 'request-order',
                        pathMatch: 'full',
                    },
                    {
                        path: 'request-order',
                        canActivate: [validateAccess(ACCESS_ROUTE.REQUEST_ORDER)],
                        loadChildren: () => import('../app/modules/operation/order/order.module').then(m => m.OrderModule),
                    },
                    {
                        path: 'quotation',
                        canActivate: [validateAccess(ACCESS_ROUTE.QUOTATION)],
                        loadChildren: () => import('../app/modules/operation/quotation/quotation.module').then(m => m.QuotationModule),
                    },
                    {
                        path: 'proposal',
                        canActivate: [validateAccess(ACCESS_ROUTE.PROPOSAL)],
                        loadChildren: () => import('../app/modules/operation/proposal/proposal.module').then(m => m.ProposalModule),
                    },
                    {
                        path: 'contract-endorsement',
                        loadChildren: () => import('../app/modules/operation/contract-endorsement/contract-endorsement.module').then(m => m.ContractEndorsementModule),
                    },
                    {
                        path: 'costing-management',
                        canActivate: [validateAccess(ACCESS_ROUTE.COSTING)],
                        loadChildren: () => import('../app/modules/operation/costing/costing.module').then(m => m.CostingModule),
                    },
                    {
                        path: 'billing',
                        canActivate: [validateAccess(ACCESS_ROUTE.BILLING)],
                        loadChildren: () => import('../app/modules/operation/billing/billing.module').then(m => m.BillingModule),
                    },
                    {
                        path: 'claim',
                        loadChildren: () => import('../app/modules/operation/claim/claim.module').then(m => m.ClaimModule),
                    },
                ],
            },

            {
                path: 'finance',
                children: [
                    {
                        path: '',
                        redirectTo: 'invoice',
                        pathMatch: 'full',
                    },
                    {
                        path: 'invoice',
                        canActivate: [validateAccess(ACCESS_ROUTE.INVOICE)],
                        loadChildren: () => import('./modules/finance/invoice/invoice.module').then(m => m.InvoiceModule),
                    },
                    {
                        path: 'voucher',
                        loadChildren: () => import('./modules/finance/voucher/voucher.module').then(m => m.VoucherModule),
                    },
                    {
                        path: 'payment',
                        canActivate: [validateAccess(ACCESS_ROUTE.PAYMENT)],
                        loadChildren: () => import('../app/modules/finance/payment/payment.module').then(m => m.PaymentModule),
                    },
                    {
                        path: 'receipt',
                        canActivate: [validateAccess(ACCESS_ROUTE.RECEIPT)],
                        loadChildren: () => import('../app/modules/finance/receipt/receipt.module').then(m => m.ReceiptModule),
                    },
                    {
                        path: 'cash-transactions',
                        loadChildren: () => import('../app/modules/finance/cash-transactions/cash-transactions.module').then(m => m.CashTransactionsModule),
                    },
                    {
                        path: 'bank-reconciliation',
                        loadChildren: () => import('../app/modules/finance/bank-reconciliation/bank-reconciliation.module').then(m => m.BankReconciliationModule),
                    },
                    {
                        path: 'cash-bank-account',
                        loadChildren: () => import('../app/modules/finance/cash-bank-account/cash-bank-account.module').then(m => m.CashBankAccountModule),
                    },
                    {
                        path: 'journal-entries',
                        canActivate: [validateAccess(ACCESS_ROUTE.JOURNAL)],
                        loadChildren: () => import('../app/modules/finance/journal-entries/journal-entries.module').then(m => m.JournalEntriesModule),
                    },
                    {
                        path: 'trial-balance',
                        loadChildren: () => import('../app/modules/finance/trial-balance/trial-balance.module').then(m => m.TrialBalanceModule),
                    },
                    {
                        path: 'fixed-assets',
                        loadChildren: () => import('../app/modules/finance/fixed-assets/fixed-assets.module').then(m => m.FixedAssetsModule),
                    },
                    {
                        path: 'ledger',
                        canActivate: [validateAccess(ACCESS_ROUTE.LEDGER)],
                        loadChildren: () => import('../app/modules/finance/ledger/ledger.module').then(m => m.LedgerModule),
                    },
                    {
                        path: 'period-maintenance',
                        canActivate: [validateAccess(ACCESS_ROUTE.LEDGER)],
                        loadChildren: () => import('../app/modules/finance/period-maintenance/period-maintenance.module').then(m => m.PeriodMaintenanceModule),
                    },
                ],
            },

            {
                path: 'reports',
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'reports'
                    },
                    {
                        path: 'reports',
                        canActivate: [validateAccess(ACCESS_ROUTE.REPORTS)],
                        loadChildren: () => import('../app/modules/reports/reports/reports.module').then(m => m.ReportsModule),
                    },
                    {
                        path: 'data-analytics',
                        loadChildren: () => import('../app/modules/reports/data-analytics/data-analytics.module').then(m => m.DataAnalyticsModule),
                    },
                ],
            },

            {
                path: 'system',
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'table-maintenance'
                    },
                    {
                        path: 'table-maintenance',
                        canActivate: [validateAccess(ACCESS_ROUTE.TABLE_MAINTENANCE)],
                        loadChildren: () => import('../app/modules/system/table-maintenance/table-maintenance.module').then(m => m.TableMaintenanceModule),
                    },
                    {
                        path: 'users',
                        canActivate: [validateAccess(ACCESS_ROUTE.USER_MAINTENANCE)],
                        loadChildren: () => import('../app/modules/system/users/users.module').then(m => m.UsersModule),
                    },
                    {
                        path: 'roles',
                        canActivate: [validateAccess(ACCESS_ROUTE.ACCESS_RIGHTS_MAINTENANCE)],
                        loadChildren: () => import('../app/modules/system/roles/roles.module').then(m => m.RolesModule),
                    },
                    {
                        path: 'batch-submission',
                        canActivate: [validateAccess(ACCESS_ROUTE.BATCH_SUBMISSION)],
                        loadChildren: () => import('../app/modules/system/batch-transaction/batch-transaction.module').then(m => m.BatchTransactionModule),
                    },
                ],
            },


        ]
    },

    {
        path: '**',
        redirectTo: 'home'
    }
];
